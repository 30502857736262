import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CorporateService {
  constructor(
    private http: HttpClient,
    private toasterService: ToastrService,
    private router: Router
  ) {}

  //
  verifyEmailOtp(body) {
    const href = environment["verfiyCoporateEmailOtp"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  resendEmailOtp(body) {
    const href = environment["resendCorporateEmailOtp"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  getAllPatients(params?: any): Observable<any> {
    const href = environment.getAllPatients;
    let httpParams = new HttpParams();
    if (params) {
      for (const key in params) {
        if (
          params.hasOwnProperty(key) &&
          params[key] !== null &&
          params[key] !== undefined &&
          key !== "limit" &&
          key !== "totalDocuments"
        ) {
          httpParams = httpParams.append(key, params[key]);
        }
      }
    }
    return this.http.get<any>(href, { params: httpParams }).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }
  // getAllPatients(search?, page?: any) {
  //   const href = environment["getAllPatients"];
  //   let params = new HttpParams();
  //   if (search) {
  //     params = params.append("search", search);
  //   }
  //   return this.http.get<any>(`${href}?page=${page}`, { params }).pipe(
  //     map((data: any) => {
  //       if (data.status == "success") {
  //         return data;
  //       }
  //     })
  //   );
  // }

  sendArchiveReq(params: any) {
    const href = environment["archiveReq"];
    return this.http.get<any>(`${href}/${params}`).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  makePatientActive(params) {
    const href = environment["activePatient"];
    return this.http.get<any>(`${href}/${params}`).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  patientCreateReferal(body) {
    const href = environment["referProvider"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  referProvider(body) {
    const href = environment["createNewRefer"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  editReferProvider(body) {
    const href = environment["editReferProvider"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  getspecialityLists(search?) {
    const href = environment["allSpecialities"];
    return this.http.get<any>(`${href}?search=${search}`).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  getReferedProviders(search?, page?: any) {
    const href = environment["getReferedProviders"];
    let params = new HttpParams();
    if (search) {
      params = params.append("search", search);
    }
    return this.http.get<any>(`${href}?page=${page}`, { params }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  getPatientDocs(patientId) {
    const href = environment["getPatientDoc"];

    return this.http.get<any>(`${href}/${patientId}`).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  searchProfessionals(search) {
    const href = environment["searchCorpProviders"];
    return this.http.get<any>(href, { params: search }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getAllProfessionals(search?, page?: any) {
    let params = new HttpParams();
    if (search) {
      params = params.append("search", search);
    }
    const href = environment["getAllProfessionals"];
    return this.http.get<any>(`${href}?page=${page}`, { params }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getReferalInfo(faxId) {
    const href = environment["getReferalInfo"];
    return this.http.get<any>(`${href}/${faxId}`).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  sendReferalInfo(payload) {
    const href = environment["sendReferalInfo"];
    return this.http.post<any>(href, payload).pipe(
      map((data: any) => {
        if (data.status == "success") {
          this.toasterService.success(data.response);
          setTimeout(() => {
            this.router.navigate(["/"]);
          }, 2000);
          return data.response;
        }
      })
    );
  }

  sendReferalDocs(data) {
    const href = environment["sendReferalDocs"];
    return this.http.post<any>(href, data).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getAllArchivePatients(search?, page?: any) {
    const href = environment["getArchivePatients"];
    let params = new HttpParams();
    if (search) {
      params = params.append("search", search);
    }
    return this.http.get<any>(`${href}?page=${page}`, { params }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  getAllNotifications() {
    const href = environment["getAllNotifications"];
    return this.http.get<any>(`${href}`).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  readAllNotifications() {
    const href = environment["readAllNotifications"];
    return this.http.post<any>(`${href}`, null).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  notReadNotifications() {
    const href = environment["notReadNotifications"];
    return this.http.get<any>(`${href}`).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  getTimeLineStatus(patientId: any, timeZone) {
    const href = environment["getTimeLineStatus"];
    return this.http.get<any>(`${href}/${patientId}?timeZone=${timeZone}`).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
}
