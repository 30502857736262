import { Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  public includeUrls = [
    '/doctor-details',
    '/book-appointment/'
  ]
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      // 'Content-Type': 'application/json',
      // 'Accept': 'application/json'
    };

    const token = this.authenticationService.getToken();
    const tempUser = this.authenticationService.getTempUser();
    // console.log("🚀 ~ file: http.token.interceptor.ts ~ line 19 ~ HttpTokenInterceptor ~ intercept ~ tempUser", this.router.url)
    if (tempUser && (this.router.url.indexOf('/doctor-details') !== -1 || this.router.url.indexOf('/book-appointment') !== -1)) {
      headersConfig['x-temp-user'] = `${tempUser["_id"]}`;
    }
    if (token) {
      headersConfig['Authorization'] = `Bearer ${token}`;
    }

    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}
