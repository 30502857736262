import { urls } from './url';

export const environment = {
  production: true,
  // host: 'https://staging.hutano.com',
  // host: 'https://production.hutano.com',
  host: 'https://hutano.com',

  ...urls,
  agora: {
    appId: 'c7630b5181e74c63b20d6584988781b4'
    // appId: 'db91b3e9a6eb40f7b5370cfb6daeb3e6'
  },

  stripeKey: 'pk_live_zS0abZnamyBWOHpaC4PxgF7X0097JjrYiQ', // Live
  // stripeKey: 'pk_test_LlxS6SLz0PrOm9IY9mxM0LHo006tjnSqWX', //staging

  firebase: {
    apiKey: "AIzaSyDZ8l7TDi83na1Xiv16AiTioblpz1IbfGw",
    authDomain: "hutano-737fd.firebaseapp.com",
    projectId: "hutano-737fd",
    storageBucket: "hutano-737fd.appspot.com",
    messagingSenderId: "926880958710",
    appId: "1:926880958710:web:fcf0fc36bc4ae8c3f7c4d7",
    measurementId: "G-8R2MN55N5Y"
  },

};
