/**
 * API URLS
 */
export const urls = {
  proxy: "/api/proxy",
  // providers: "/api/featured-providers",
  providers: "/api/active-featured-providers",
  //------------------------- Admin Routes
  login: "/admin/login",
  licenceState: "/admin/licence-state",
  professionalTitle: "/admin/professionalTitle",
  specialty: "/admin/specialty",
  service: "/admin/service",
  subService: "/admin/sub-service",
  insurance: "/admin/insurance",
  patient: "/admin/patient",
  provider: "/admin/provider",
  providerTitle: "/admin/professionalTitle",
  viewProvider: "/admin/professionalTitle",
  viewPatient: "/admin/professionalTitle",
  addLicenceState: "/admin/licence-state",
  deleteLicenceState: "/admin/delete/licence-state",
  addProviderTitle: "/admin/professionalTitle",
  deleteProviderTitle: "/admin/delete/professionalTitle",
  deleteSpeciality: "/admin/delete/specialty",
  deleteService: "/admin/delete/service",
  deleteSubService: "/admin/delete/sub-service",
  getServicesBySpeciality: "/admin/service-specialty",
  viewProviderDetails: "/admin/provider-details",
  viewPatientDetails: "/admin/patient-details",
  searchProfessionalTitle: "/admin/search/professionalTitle",
  editProviderTitle: "/admin/update-professionalTitle",
  updateSpeciality: "/admin/update-specialty",
  updateService: "/admin/update-service",
  updateSubService: "/admin/update-sub-service",
  getTopSpeciality: "/admin/top-specialty",
  TopSpecialityProvider: "/admin/top-specialty/provider",
  editTopSpecialityProvider: "/admin/top-specialty/provider",
  getProvidersBySpeciality: "/admin/provider-specialty",
  deleteTopSpeciality: "/admin/delete-top-specialty",
  deleteTopSpecialityProvider: "/admin/delete-top-specialty-doctor",
  updateTopSpecialityProvider: "/admin/update-top-specialty",
  getStates: "/admin/states",
  deletePatientProvider: "/admin/delete-user",
  adminProviderDegree: "/admin/education-qualification",
  adminListProviderDegree: "/admin/education-qualification-list",
  adminCms: "/admin/content",
  adminAboutUs: "/admin/about-us",
  adminContactUs: "/admin/contact-us",
  adminReasons: "/admin/reasons",
  updateTestAndMeasures: "/admin/test-update",
  adminContactUsFormListings: "/admin/get-contact-us-form-list",
  deleteAdminContactUsFormListings: "/admin/delete-contact-us-form-list",
  promoCodeList: "/admin/promocode-list",
  addPromoCode: "/admin/add-promocode",
  deletePromoCode: "/admin/delete-promocode",
  insuranceCompany: "/admin/insurance",
  updateInsuranceCompany: "/admin/insurance-update",
  deleteInsuranceCompany: "/admin/insurance-delete",
  getAdminPatientMedicalHistory: "/admin/problem-list",
  getAdminTestAndMeasure: "/admin/test-list",
  addAdminPatientMedicalHistory: "/admin/add-problem",
  viewAdminPatientMedicalHistory: "/admin/problem-details",
  updateAdminPatientMedicalHistory: "/admin/problem-update",
  deleteAdminPatientMedicalHistory: "/admin/test-delete",
  deleteAdminTestMeasures: "/admin/test-delete",
  preferredPharmacy: "/admin/pharmacy-list",
  preferredPharmacyDetails: "/admin/pharmacy-details",
  addPreferredPharmacy: "/admin/add-pharmacy",
  updatePreferredPharmacy: "/admin/pharmacy-update",
  deletePreferredPharmacy: "/admin/pharmacy-delete",
  diagnosticTestTypes: "/admin/dignostic-test-type-list",
  diagnosticTestTypesDetails: "/admin/dignostic-test-type-details",
  addDiagnosticTestTypes: "/admin/dignostic-test-type-add",
  updateDiagnosticTestTypes: "/admin/dignostic-test-type-update",
  deleteDiagnosticTestTypes: "/admin/dignostic-test-type-delete",

  labs: "/admin/lab-list",
  labDetails: "/admin/lab-details",
  addLab: "/admin/lab-add",
  updateLab: "/admin/lab-update",
  deleteLab: "/admin/lab-delete",

  referralType: "/admin/referral-type-list",
  referralTypeDetails: "/admin/referral-type-details",
  addReferralType: "/admin/referral-type-add",
  updateReferralType: "/admin/referral-type-update",
  deleteReferralType: "/admin/referral-type-delete",

  heartSoundList: "/admin/deficits-list",
  allergyType: "/admin/allergy-type-list",
  allergyTypeDetails: "/admin/allergy-type-details",
  addAllergyType: "/admin/allergy-type-add",
  updateAllergyType: "/admin/allergy-type-update",
  deleteAllergyType: "/admin/allergy-type-delete",

  labTests: "/admin/lab-test-list",
  labTestsDetails: "/admin/lab-test-details",
  addLabTest: "/admin/lab-test-add",
  updateLabTest: "/admin/lab-test-update",
  deleteLabTest: "/admin/lab-test-delete",

  prescriptions: "/admin/prescription-list",
  prescriptionsDetails: "/admin/prescription-details",
  addPrescriptions: "/admin/prescription-add",
  updatePrescriptions: "/admin/prescription-update",
  deletePrescriptions: "/admin/prescription-delete",

  exercises: "/admin/exercise-list",
  exerciseDetails: "/admin/exercise-details",
  addExercises: "/admin/exercise-add",
  updateExercises: "/admin/exercise-update",
  deleteExercises: "/admin/exercise-delete",

  imagings: "/admin/imaging-list",
  imagingDetails: "/admin/imaging-details",
  addImaging: "/admin/imaging-add",
  updateImaging: "/admin/imaging-update",
  deleteImaging: "/admin/imaging-delete",

  preferredImagingCenters: "/admin/prefer-imaging-list",
  preferredImagingCenterDetails: "/admin/prefer-imaging-details",
  addPreferredImagingCenter: "/admin/prefer-imaging-add",
  updatePreferredImagingCenter: "/admin/prefer-imaging-update",
  deletePreferredImagingCenter: "/admin/prefer-imaging-delete",

  medicalHistory: "/admin/medical-history-list",
  medicalHistoryDetails: "/admin/medical-history-details",
  addMedicalHistory: "/admin/medical-history-add",
  updateMedicalHistory: "/admin/medical-history-update",
  deleteMedicalHistory: "/admin/medical-history-delete",

  icdCode: "/admin/icd-code-list",
  icdCodeDetails: "/admin/icd-code-details",
  addIcdCode: "/admin/icd-code-add",
  updateIcdCode: "/admin/icd-code-update",
  deleteIcdCode: "/admin/icd-code-delete",

  cptCode: "/admin/cpt-code-list",
  cptCodeDetails: "/admin/cpt-code-details",
  addCptCode: "/admin/cpt-code-add",
  updateCptCode: "/admin/cpt-code-update",
  deleteCptCode: "/admin/cpt-code-delete",

  uploadImage: "/admin/upload-image",

  typesClinicalConcern: "/admin/clinical-concern-types",
  listClinicalConcern: "/admin/clinical-concern-list",
  updateClinicalConcern: "/admin/clinical-concern-update",
  addClinicalConcern: "/admin/clinical-concern-add",
  deleteClinicalConcern: "/admin/clinical-concern-delete",
  detailsClinicalConcern: "/admin/clinical-concern-details",

  typesBodyMeasures: "/admin/deficits-types",
  listBodyMeasures: "/admin/deficits-list",
  listBodyParts: "/admin/body-parts",
  updateBodyMeasures: "/admin/deficits-update",
  updateBodyPart: "/admin/body-parts/upsert",
  addBodyMeasures: "/admin/deficits-add",
  deleteBodyMeasures: "/admin/deficits-delete",
  deleteBodyPart: "/admin/body-parts/delete",
  detailsBodyMeasures: "/admin/deficits-details",
  detailsBodyPart: "/admin/body-parts/detail",
  enableFeatures: "/admin/featured-provider",
  searchUsers: "/admin/search-user",
  sendNotification: "/admin/send-notification",
  // ----------------------------------------FrontEnd(Patient)

  getSpecialities: "/api/specialties-list",
  searchProfessionals: "/api/provider-name/professional-title",
  specialitiesWithTitle: "/admin/title-specialty",
  AllTopSpecialityProvider: "/api/all-top-specialty/provider",
  providerList: "/api/search-web",
  searchProviders: "/api/search",
  searchServicesByName: "/api/patient/name/specialty/service",
  userLogin: "/auth/api/login",
  userLogout: "/auth/api/logout",
  resetPassword: "/auth/api/reset-password",
  verifyEmail: "/api/email-verification",
  signUp: "/auth/api/web-register",
  popularProvider: "/api/popular-provider",
  regenerateToken: "/auth/api/generate-token",
  docterDetails: "/api/patient/doctor-details",
  doctorScheduleDetails: "/api/doctor-slots",
  bookAppointment: "/api/patient/appointment-booking-v1",
  // getMedicalProblems: '/api/disease',/api/patient/selected-problem-list
  getMedicalProblems: "/api/patient/problem-list",
  getPatientAppointments: "/api/patient/doctor-appointment-list",
  getMedicalHistory: "/api/disease",
  getAllAllergy: "/api/patient/medical-allergy",
  getSelectedMedicalHistoryDetails: "/api/patient/selected-problem-list",
  TopSpecialistsHomePage: "/admin/top-specialty",
  professionalListTitle: "/api/professional-titles",
  specialtiesList: "/api/specialties-list",
  degreeList: "/api/education-qualification",
  languages: "/api/languages",
  twoWayProvider: "/api/all-specialty/provider",
  getSelectedMedicalData: "/api/patient/medical-images-documents",
  getAllInsuranceCompany: "/api/insurance",
  // setPaymentIntent:"/api/stripe-setUp-intent",
  getSavedCard: "/api/stripe-card",
  getSavedInsurance: "/api/patient/user-details",
  updateInsurance: "/api/profile/update",
  setPaymentIntent: "/api/stripe-setUp-intent",
  deleteCard: "/api/delete-stripe-card",
  addDebitCard: "/api/doctor/confirm-setUp-intent",
  isEmailExist: "/api/check-email",
  postInsurance: "/api/patient/add-insurance",
  getInsurance: "/api/patient/insurances",

  removeCard: "/api/delete-stripe-card",
  saveMedicalHistory: "/api/patient/medical-history",
  saveAddress: "/api/patient/address",
  getAddress: "/api/patient/address",
  deleteAddress: "/api/patient/delete-address",
  editAddress: "/api/patient/edit-address",
  editProfile: "/api/patient/profile-update",
  removeInsurance: "/api/patient/insurance-remove",
  getSavedDoctors: "/api/patient/saved-doctors",
  getOndemandDoctor: "/api/patient/ondemand-available-doctors",
  getAppoinmentList: "/api/patient/user-schedule-appointmnet",
  getAppointmentDetail: "/api/patient/doctor-appointment-details",
  getRequestList: "/api/patient/user-schedule-appointmnet-pending",
  getNotificationList: "/api/patient/notification-list",
  patientMedicalImages: "/api/patient/images",
  patientMedicalDocuments: "/api/patient/medical-documents",
  patientMedicalDiagnostics: "/api/patient/medical-diagnostics",
  getMedicalImagesDocuments: "/api/patient/medical-images-documents",
  deleteMedicalImage: "/api/patient/delete-image",
  deleteMedicalDocuments: "/api/patient/delete-medical-documents",
  deleteMedications: "/api/patient/delete-medications",
  cancelRequest: "/api/patient/cancel-request",
  cancelAppointment: "/api/patient/cancel-appointment",
  getProviderDates: "/api/get-seven-day-doctor-slot",
  // getProviderDates: '/api/get-doctor-available-dates',
  getDoctorPaymentDetail: "/api/patient/doctor-paymentMethod-insurence",
  patientEnterVWR: "/api/patient/join-call",
  patientInviteRejoin: "/api/patient/rejoin-call",
  patientRasons: "/api/patient/reason",
  savePatientReview: "/api/patient/provider-rating",
  doctorOfficeLocation: "/api/patient/doctor-location-address",
  confirmDoctorArrived: "/api/onsite/appointment-tracking-status",
  sendMobileOtp: "/auth/api/send-phone-verification-code",
  verifyPhoneOtp: "/auth/api/verify-phone-verification-code",
  setRegistrationPassword: "/auth/api/set-password",
  changePatientPassword: "/auth/api/change-password",
  emailVerify: "/auth/api/verify-email-verification-code",
  resendPhoneCode: "/auth/api/resend-phone-verification-code",
  resendCodeCode: "/auth/api/resend-email-verification-code",
  resetUserPassword: "/auth/api/phone-reset-password",
  getRegistrationStatus: "/api/patient/patient-details",
  checkPaymentDetail: "/api/patient/check-card-insurence-added",
  getPaymentHistory: "/api/patient/stripe-statements",
  getNotificationCount: "/api/patient/unread-notification-list",
  readNotificationCount: "/api/patient/notification-read",
  searchPharmacy: "/api/search-pharmacy",
  searchDoctorAppoinment: "/api/patient/search-doctor-appointment-list",
  recentChats: "/api/patient/recent-chats",
  chatDetails: "/api/patient/chat-details",
  // Provider End Routes
  getAllPreferences: "/api/doctor/preferences",
  savePreferences: "/api/doctor/preferences",
  getAllConcerns: "/api/doctor/concerns",
  getHeartAndLungSound: "/api/doctor/heart-and-lung-sound",
  getBodyMeasures: "/api/doctor/body-measures",
  getFunctionalOptions: "/api/doctor/functional-options",
  getAllEducationCategories: "/api/doctor/education-category",
  getEducationReferenceSearch: "/api/doctor/education-reference-search",
  getBodyParts: "/api/doctor/body-parts",

  postRegister: "/auth/api/web-register",
  updateProvider: "/api/profile/update",
  getProfessionalTitle: "/api/professional-titles",
  getDegreeList: "/api/education-qualification",
  getLicenceStates: "/admin/states",
  addLicenseDetails: "/api/doctor/profile/upload-file",
  addDocumentDetails: "/api/doctor/profile/upload-file",
  saveDocuments: "/api/doctor/profile/upload-file",
  getStripePlan: "/api/stripe-plan",
  getServicesFromSpeciality: "/api/specialty/services",
  saveServicesSubservices: "/api/doctor/service",
  docterAppointmentType: "/api/doctor/schedules",
  docterAppointmentSetting: "/api/doctor/appointment-Setting",
  createStripeSubscription: "/api/create-stripe-subscription",
  updateStripeSubscription: "/api/update-stripe-subscription",
  resendOtp: "/auth/api/resend-otp",
  checkEmailPhone: "/api/check-email",
  getDocterSchedules: "/api/doctor/schedules",
  deleteSchedules: "/api/doctor/delete-schedules",
  editDoctorSchedule: "/api/doctor/edit-schedules-new",
  addDoctorSchedule: "/api/doctor/add-schedules",
  providerDetails: "/api/doctor/doctor-details",
  profileDetail: "api/profile/update",
  updateProviderDetails: "/api/doctor/profile/update",
  providerDashboardAppointments: "/api/doctor/dashboard/appointment",
  providerDashboardEarnings: "/api/doctor/income",
  providerDashboardReviews: "/api/doctor/dashboard/reviews",
  providerDashboardPatients: "/api/doctor/dashboard/patients",
  providerDashboardAppointmentCount: "/api/doctor/dashboard/appointment-count",
  providerDashboardManageAppointments:
    "/api/doctor/doctor-present-past-appointments",
  providerDashboardAppointmentDetails:
    "/api/doctor/patient-appointment-details",
  providerDashboardAppointmentRequests: "/api/doctor/appointment-requests",
  providerCancelAppointment: "/api/doctor/cancel-appointment",
  getProviderPatients: "/api/doctor/my-patient",
  getProviderPatientDetails: "/api/doctor/my-patient-details",
  providerAcceptAndRejectAppointment: "/api/doctor/appointment-status",
  getSavedDoctorEMR: "/api/doctor/appointment-finish-followup/saved",
  saveDoctorEMR: "/api/doctor/appointment-finish-followup/save",
  uploadFiles: "/api/upload/files",
  getAllReferredPatients: "/api/doctor/referred-patient",
  getSingleReferredPatients: "/api/doctor/referred-patient",
  acceptORDenyReferral: "/api/doctor/accept-deny/referral",
  // setPaymentIntent: "/api/stripe-setUp-intent",
  getProviderscardList: "/api/stripe-card",
  deleteProvidercard: "/api/delete-stripe-card",
  addExternalAch: "/api/doctor/add-external-account-ach",
  startOfficeTreatment: "/api/appointment-tracking-status",
  completeOfficeTreatment: "/api/doctor/medical-treatment",
  providerOnsiteAppointmentTracking: "/api/onsite/appointment-tracking-status",

  imageBaseUrl: "https://hutano-assets.s3.amazonaws.com/",
  providerFilterOption: "/api/provider-filter-options",
  unreadProviderNotification: "/api/doctor/notification-list-unread",
  readProviderNoitification: "/api/doctor/notification-read",
  apiProviderRegister: "/auth/api/register",
  setPrimaryCard: "/api/user-primary-card",
  doctorSpecialty: "/api/doctor/professionaltitle-specialty",
  addLicenseOneByOne: "/api/doctor/licence-add-update",
  addNewLicenseIndividual: "/api/doctor/add-new-licence",
  removeProviderLicense: "/api/doctor/remove-licence",
  getPatientRatingData: "/api/doctor/reason",
  postPatientRatingData: "/api/doctor/patient-rating",
  makeProviderOnline: "/api/doctor/on-demand-add-online",
  makeProviderOffline: "/api/doctor/on-demand-offline",
  providerActivity: "/api/doctor/all-notifications",
  trackProviderOnlineOffline: "/api/doctor/check-schedule-timeslot",
  chekProviderRegistrationStatus: "/api/doctor/check-registration-process",
  getProviderCompliments: "/api/doctor/dashboard/compliments",
  changeProviderPassword: "/auth/api/change-password",
  addProviderBankAccount: "/api/doctor/add-external-bank-account",
  getProviderBankAccounts: "/api/doctor/external-bank-account",
  deleteProviderBankAccount: "/api/doctor/delete-external-bank-account",
  addProviderDefaultAccount: "/api/doctor/set-default-external-bank-account",
  providerRejoinCall: "/api/doctor/rejoin-call",
  providerCheckAppointments: "/api/doctor/appointments-date-time-list",
  providerCancelBulkAppointment: "/api/doctor/cancel-bulk-appointment",
  providerBusinessAddres: "/api/doctor/business-location",
  deleteProviderBusinessAddress: "/api/doctor/delete-business-location",
  checkProviderOnDemandAppointments:
    "/api/doctor/on-demand-accepted-appointments",
  getProviderPayoutStatement: "/api/doctor/stripe-statement",
  sendStripeStatementToEmail: "/api/doctor/send-stripe-statement-to-email",
  resendEmailOtp: "/auth/api/email-sendotp",
  verifyEmailOtp: "/auth/api/email-verify",
  validatePromoCode: "/api/doctor/promocode-check-validate",
  searchIcdCodes: "/api/doctor/icd-codes",
  searchCptCodes: "/api/doctor/cpt-codes",
  searchLabTests: "/api/search-lab-test",
  searchPreferredLabs: "/api/search-lab",
  searchImaging: "/api/search-imaging",
  searchPreferredImaging: "/api/search-prefer-imaging",
  searchExercises: "/api/search-excercise",
  searchMedicalPrescriptions: "/api/search-prescription",
  rescheduleAppointment: "/api/reschedule-appointment",
  finishAppointmentProvider: "/api/doctor/appointment-finish-followup",
  finishAppointmentProviderV2: "/api/doctor/appointment-finish-followup/v2",
  savePatientVitalNotification: "/api/doctor/vital",
  searchMedicalHistory: "/api/search-medical-history",
  deletePatientMedicalHistory: "/api/patient/delete-medical-history",
  saveGetMedicalHistory: "/api/patient/medical-history",
  searchAllergy: "/api/search-allergy",
  saveSelectedAllergy: "/api/patient/medical-allergy",
  deleteSelectedAllergy: "/api/patient/delete-medical-allergy",
  updatePatientMedicalHistory: "/api/patient/medical-history-update",
  updateAppointmentDetail: "/api/patient/appointment-booking-v1/update",
  acceptOrRejectAppointment:
    "/api/patient/appointment-booking-v1/accept-reject",
  deletePatientPreferredPharmacy: "/api/patient/preferred-Pharmacy-delete",
  addPatientInsurance: "/api/patient/add-patient-insurance",
  getPatientDashboardData: "/api/patient/patient-dashboard",
  checkPatientPrimaryInsurance: "/api/patient/check-patient-primary-insurance",
  getPatientRelationList: "/api/patient/user-relations",
  addFamilyMembers: "/api/patient/add-family-members",
  getFamilyMembers: "/api/patient/get-family-members",
  deleteProviderGroup: "/api/patient/delete-provider-group",
  updatePaymentMethod: "/api/patient/appointment/payment-method/update",
  appointmentFiles: "/api/doctor/appointment-finish-followup/v2/files",

  // saveHeartRateNotification: '/api/doctor/appointment-finish-followup',
  // saveOxygenSaturationNotification: '/api/doctor/appointment-finish-followup',
  // saveTemperatureNotification: '/api/doctor/appointment-finish-followup',
  getProviderSignature: "/api/doctor/get-provider-signature",

  endCall: "/api/video-appointment-stop",
  startCall: "/api/video-appointment",
  docterJoinCall: "/api/doctor/join-call",
  generateAgoraToken: "/api/generate-agora-token",
  checkAddedSchedulesAndBanks: "/api/doctor/check-added-schedules-banks",
  specialityImagePath: "/specialityImages/",
  getPagesContent: "/api/content",
  addAndGetPatientPreferredPharmacy: "/api/patient/preferred-Pharmacy",
  addAndGetPatientMedications: "/api/patient/medications",
  getDiagnosticTestTypes: "/api/patient/dignoStics-test-type-list",
  getOtpOverCall: "/auth/api/otp-on-call",
  getProviders: "/api/get-providers",
  getProvidersGroup: "/api/get-provider-groups",
  addEditProvidersGroup: "/api/add-edit-providers",
  getProvidersNetwork: "/api/get-all-providers",
  deleteProvidersNetwork: "/api/delete-providers",
  getAboutUsContent: "/api/about-us",
  getContactUsContent: "/api/contact-us",
  postContactUsForm: "/api/contact-us-form",
  getAppDownloadLink: "/api/app-invitation",
  searchMedications: "/api/user/patient/medication-timeline",
  addMedication: "/api/doctor/patient/medication-timeline",
  searchLocation: "/api/doctor/body-parts",
  searchUser: "/api/doctor/search-user/appointment",
  createUser: "/api/doctor/create-user",
  getAllReferredProviders: "/api/patient/referred-provider",
  getSingleReferredProvider: "/api/patient/referred-doctor-details",
  // Corporate apis
  corporateUserLogin: "/api/corporate/auth/login",
  corporateUserRegister: "/api/corporate/auth/register",
  verfiyCoporateEmailOtp: "/api/corporate/auth/verifyEmail",
  resendCorporateEmailOtp: "/api/corporate/auth/resendotp",
  getAllPatients: "/api/corporate/patients",
  createNewPatient: "/api/corporate/create-patient",
  editPatient: "/api/corporate/patient-update",
  archiveReq: "/api/corporate/archive-record",
  referProvider: "/api/corporate/patient/create-referral",
  createNewRefer: "/api/corporate/create-provider",
  editReferProvider: "/api/corporate/provider/update",
  allSpecialities: "/api/corporate/speciality-list",
  getReferedProviders: "/api/corporate/provider",
  getPatientDoc: "/api/corporate/documents",
  searchCorpProviders: "/api/corporate/provider-name",
  getAllProfessionals: "/api/corporate/provider-list",
  // getReferalInfo: "/api/corporate/fax-detail",
  getReferalInfo: "/api/corporate/referral-detail",
  sendReferalInfo: "/api/corporate/accept-deny",
  sendReferalDocs: "/api/corporate/upload-documents",
  getArchivePatients: "/api/corporate/get-archive-patient",
  activePatient: "/api/corporate/active",
  getAllNotifications: "/api/corporate/all-notification",
  readAllNotifications: "/api/corporate/update-notification",
  notReadNotifications: "/api/corporate/not-read-notifications",
  getTimeLineStatus: "/api/corporate/status-timeline",
};
