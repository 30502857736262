import { SharedModule } from "./shared/shared.module";
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { LayoutsModule } from "./core/layouts.module";
import { UsersRoutingModule } from "./modules/users/users-routing.module";
import { CoreModule } from "./core/core.module";
import { NgModule } from "@angular/core";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxImageCompressService } from "ngx-image-compress";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ToastrModule } from "ngx-toastr";
import {
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
  NgxUiLoaderHttpModule,
} from "ngx-ui-loader";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { environment } from "src/environments/environment";
import { MessagingService } from "./core/services/messaging.service";
import {
  AngularFireMessaging,
  AngularFireMessagingModule,
} from "@angular/fire/messaging";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AsyncPipe } from "@angular/common";
import { ModalModule } from "ngx-bootstrap/modal";
import { AngularFireAnalyticsModule } from "@angular/fire/analytics";

import { NgIdleKeepaliveModule } from "@ng-idle/keepalive"; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from "angular2-moment";
import {
  LyHammerGestureConfig,
  LyThemeModule,
  LY_THEME,
  LY_THEME_NAME,
  StyleRenderer,
  LyTheme2,
} from "@alyle/ui";
import { MinimaLight } from "@alyle/ui/themes/minima"; // optional, provides moment-style pipes for date formatting
import { AnalyticsSharedService } from "./core/services/analytics.service";
import { CustomToasterComponent } from "./shared/components/custom-toaster/custom-toaster.component";

const TOASTR_OPTIONS = {
  timeOut: 10000,
  // timeOut: 100000000000000000,
  positionClass: "toast-top-right",
  preventDuplicates: true,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    LayoutsModule,
    ToastrModule.forRoot({
      ...TOASTR_OPTIONS,
      toastComponent: CustomToasterComponent,
    }),
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot({
      exclude: [
        environment.host + "/api/patient/images",
        environment.host + "/api/patient/medical-documents",
        environment.host + "/api/doctor/licence-add-update",
        environment.host + "/api/doctor/profile/upload-file",
        environment.host + "/auth/api/register",
        environment.host + "/api/doctor-slots",
      ],
    }),
    Ng2SearchPipeModule,
    MatDatepickerModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    ModalModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    HammerModule,
    SharedModule,
  ],
  providers: [
    MessagingService,
    AnalyticsSharedService,
    AsyncPipe,
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
    StyleRenderer,
    LyTheme2,
    { provide: LY_THEME_NAME, useValue: "minima-light" },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    NgxImageCompressService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
