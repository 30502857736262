import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, tap, debounce } from "rxjs/operators";
import { of as observableOf, Observable, BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { Login } from "../../shared/models/login.model";
const credentialsKey = "currentUser";
const userAddress = "locationDetails";
const savedCorporate = "corporateUser";
@Injectable()
export class AuthenticationService {
  coporateLogout() {
    throw new Error("Method not implemented.");
  }
  public changedProfile = new BehaviorSubject(null);
  public showPaymentRedDot = new BehaviorSubject(null);
  private tempUser = new BehaviorSubject(null);
  public tempUserObservable = this.tempUser.asObservable();
  constructor(private http: HttpClient, private router: Router) {}

  showPaymentRedDotFalse(): Observable<any> {
    return this.showPaymentRedDot.asObservable();
  }

  setPaymentRedDotFalse(data) {
    this.showPaymentRedDot.next(data);
  }

  getChangedProfile(): Observable<any> {
    return this.changedProfile.asObservable();
  }

  setChangedProfile(data) {
    this.changedProfile.next(data);
  }

  login(loginData: Login): Observable<any> {
    const href = `${environment.login}`;
    return this.http.post<any>(href, loginData).pipe(
      tap((data) => {
        if (data.status === "success") {
          const storage = localStorage;
          storage.setItem(credentialsKey, JSON.stringify(data.response));
        }

        return data;
      })
    );
  }

  getUserId() {
    const savedCredentials = this.getUser();
    if (this.isLogin()) {
      return savedCredentials["_id"];
    } else {
      return false;
    }
  }

  userLogin(loginData: Login): Observable<any> {
    const href = `${environment.userLogin}`;
    return this.http.post<any>(href, loginData).pipe(
      tap((data) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  setToken(userDetails) {
    if (userDetails) {
      const storage = localStorage;
      storage.setItem(credentialsKey, JSON.stringify(userDetails.response));
    }
    return userDetails;
  }

  logout(): Observable<boolean> {
    sessionStorage.removeItem(credentialsKey);
    localStorage.removeItem(credentialsKey);
    sessionStorage.removeItem("tempUser");
    localStorage.removeItem("tempUser");
    // this.router.navigate(['']);
    return observableOf(true);
  }

  userLogout(): Observable<boolean> {
    sessionStorage.removeItem(credentialsKey);
    localStorage.removeItem(credentialsKey);
    this.router.navigate([""]);
    return observableOf(true);
  }

  providerLogout(): Observable<boolean> {
    sessionStorage.removeItem(credentialsKey);
    localStorage.removeItem(credentialsKey);
    // this.router.navigate(['']);
    return observableOf(true);
  }

  getUserInfo(sync = false): Observable<any> | any {
    const savedCredentials = this.getUser();
    if (sync) {
      return savedCredentials;
    }
    return observableOf(savedCredentials);
  }

  isLogin() {
    const user = localStorage.getItem(credentialsKey);
    if (user) {
      return true;
    }
    return false;
  }

  getOtpDetails() {
    if (sessionStorage.getItem("otpDetails")) {
      const otpDetails = sessionStorage.getItem("otpDetails");
      return JSON.parse(otpDetails);
    }
  }

  isProviderLogin() {
    const savedCredentials = this.getUser();
    if (this.isLogin()) {
      if (savedCredentials["type"] === 2) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isAdministratorLogin() {
    const savedCredentials = this.getUser();

    if (this.isLogin()) {
      if (savedCredentials["type"] === 4) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getToken() {
    const savedCredentials = this.getUser();
    const savedCorporateCredentials = this.getCorporate();
    if (this.isLogin()) {
      const token = savedCredentials["token"];
      return token;
    }
    if (this.isCorporateUserLogin()) {
      const tokenValue = savedCorporateCredentials["tokens"];
      const token = tokenValue[0]["token"];
      return token;
    }
  }

  getUserEmail() {
    const savedCredentials = this.getUser();
    if (this.isLogin()) {
      return savedCredentials["email"];
    } else {
      return false;
    }
  }

  getUserPhoneNumber() {
    const savedCredentials = this.getUser();
    if (this.isLogin()) {
      return savedCredentials["phoneNumber"];
    } else {
      return false;
    }
  }

  getUserDetails() {
    const savedCredentials = this.getUser();
    if (this.isLogin()) {
      return savedCredentials;
    } else {
      return false;
    }
  }

  getUserType() {
    const savedCredentials = this.getUser();
    if (this.isLogin()) {
      return savedCredentials["role"];
    } else {
      return false;
    }
  }

  private getUser() {
    const savedCredentials =
      sessionStorage.getItem(credentialsKey) ||
      localStorage.getItem(credentialsKey);
    return JSON.parse(savedCredentials);
  }

  private getLocation() {
    const userLocationDetails =
      sessionStorage.getItem(userAddress) || localStorage.getItem(userAddress);
    return JSON.parse(userLocationDetails);
  }

  getUserLocation() {
    const savedLocation = this.getLocation();
    if (savedLocation) {
      return savedLocation[0];
    } else {
      const savedLocation = {
        address: "Las Vegas, NV, USA",
        latitude: 36.1699,
        longitude: -115.1398,
      };
      return savedLocation;
    }
  }

  setUserLocation(data) {
    localStorage.setItem(userAddress, JSON.stringify(data));
  }

  setUser(user: any): void {
    localStorage.setItem(credentialsKey, JSON.stringify(user));
  }

  providerLogin(loginData: Login): Observable<any> {
    const href = `${environment.userLogin}`;
    // const faxID = localStorage.getItem("faxId");
    // console.log(faxID);
    return this.http.post<any>(href, loginData).pipe(
      tap((data) => {
        if (data.status === "success") {
          const storage = localStorage;
          // if (faxID) {
          //   storage.setItem(
          //     "providerToken",
          //     JSON.stringify(data.response.token)
          //   );
          // } else {
          storage.setItem(credentialsKey, JSON.stringify(data.response));
          // }
        }
        return data;
      })
    );
  }

  setTempUser(user) {
    localStorage.setItem("tempUser", JSON.stringify(user));
    return true;
  }

  deleteTempUser() {
    localStorage.removeItem("tempUser");
    return true;
  }
  getTempUser() {
    return JSON.parse(localStorage.getItem("tempUser") || "null");
  }

  /** Corporate  USer realted APi calling and function declaration**/
  setCorporateUser(user) {
    localStorage.setItem("corporateUser", JSON.stringify(user));
  }

  isCorporateUserLogin() {
    const user = localStorage.getItem(savedCorporate);
    if (user) {
      return true;
    }
    return false;
  }

  private getCorporate() {
    const savedCredentials =
      sessionStorage.getItem(savedCorporate) ||
      localStorage.getItem(savedCorporate);
    return JSON.parse(savedCredentials);
  }
  isCorporateLogin() {
    const savedCredentials = this.getCorporate();

    if (this.isCorporateUserLogin()) {
      if (savedCredentials["type"] === 4) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  getCoporateUser() {
    const corporateUserEmail = JSON.parse(
      localStorage.getItem("corporateUser")
    );
    return corporateUserEmail["email"];
  }
  corporateUserRegister(registerData) {
    const href = `${environment.corporateUserRegister}`;
    return this.http.post<any>(href, registerData).pipe(
      tap((data) => {
        if (data.status === "success") {
          // const storage = localStorage;
          // storage.setItem(credentialsKey, JSON.stringify(data.response));
          return data;
        }
      })
    );
  }

  corporateUserLogin(loginData) {
    const href = `${environment.corporateUserLogin}`;
    return this.http.post<any>(href, loginData).pipe(
      tap((data) => {
        if (data.status === "success") {
          const storage = localStorage;
          storage.setItem(savedCorporate, JSON.stringify(data.response));
        }
        return data;
      })
    );
  }

  corporateLogout(): Observable<boolean> {
    sessionStorage.removeItem(savedCorporate);
    localStorage.removeItem(savedCorporate);
    // this.router.navigate(['']);
    return observableOf(true);
  }
}
